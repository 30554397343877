import { Alert, Button, Card, Tooltip } from "flowbite-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const OnCallPage = () => {
  const navigate = useNavigate();

  const handleFinishCall = () => {
    navigate("/main");
  };

  const allowedCall = 3;
  const [callCount, setCallCount] = useState(0);
  const handleRecall = () => {
    setCallCount(callCount + 1);
  };

  const handleRescheduleCall = () => {
    navigate("/main");
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold mb-8">On Call</h1>
      <Card className="max-w-m">
        {/* <h2 className="font-bold text-lg mb-4">Respondent: John Doe</h2> */}
        <p className="mb-2">
          <span>Respondent:</span> <span className="font-bold text-lg">John Doe</span>
        </p>
        <p className="text-gray-500 mb-4">On survey: Number 1 how to make monde for amateur</p>
        {/* Other call-related information */}
        {callCount > 0 && (
          <Alert color="warning" rounded>
            <span>
              <p>
                <span className="font-medium mr-1">Respondent has not answered the call {callCount} time!</span>
                {callCount < allowedCall && <span>{allowedCall - callCount} more tries remaining</span>}
              </p>
            </span>
          </Alert>
        )}
        <span className="text-red-600 font-bold">coba pencet recall</span>
        <br />
        <div className="flex space-x-4 self-center">
          <div className="flex-auto">
            <Button size="sm" onClick={handleFinishCall} color="success" pill>
              <p>Finish Call</p>
            </Button>
          </div>
          {callCount < allowedCall && (
            <div className="flex-auto">
              <Tooltip content="Click this only if respondent didn't respond">
                <Button size="sm" onClick={handleRecall} color="warning" pill>
                  <p>Recall</p>
                </Button>
              </Tooltip>
            </div>
          )}
          {callCount >= allowedCall && (
            <div className="flex-auto">
              <Button size="sm" onClick={handleRescheduleCall} color="failure" pill>
                <p>Reschedule</p>
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default OnCallPage;
