import { Card } from "flowbite-react";

function Profile() {
    return (
      <Card>
        Profile
      </Card>
    )
}

export default Profile;