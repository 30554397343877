import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, DarkThemeToggle, Dropdown, Avatar, Sidebar } from "flowbite-react";
import { HiChartPie, HiInbox, HiShoppingBag, HiUser } from "react-icons/hi";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      <Navbar fluid rounded className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <Navbar.Brand onClick={() => setCollapsed(!collapsed)}>
          {/* href="https://flowbite-react.com" */}
          <img alt="App Logo" className="mr-3 h-6 sm:h-9" src="logo192.png" />
          <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Application</span>
        </Navbar.Brand>
        <div className="flex md:order-2">
          <DarkThemeToggle className="mr-10" />
          <Dropdown inline label={<Avatar alt="User settings" img="https://avatars.githubusercontent.com/u/36720633?v=4" rounded />}>
            <Dropdown.Header>
              <span className="block text-sm">Roni Seti</span>
              <span className="block truncate text-sm font-medium">roni@ronisetiawan.id</span>
            </Dropdown.Header>
            <Dropdown.Item onClick={() => navigate("/profile")}>Profile</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>Earnings</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item>Sign out</Dropdown.Item>
          </Dropdown>
          {/* <Navbar.Toggle /> */}
        </div>
        {/* <Navbar.Collapse>
          <Navbar.Link active href="#">
            <p>Home</p>
          </Navbar.Link>
          <Navbar.Link href="#">About</Navbar.Link>
          <Navbar.Link href="#">Services</Navbar.Link>
          <Navbar.Link href="#">Pricing</Navbar.Link>
          <Navbar.Link href="#">Contact</Navbar.Link>
        </Navbar.Collapse> */}
      </Navbar>
      <div className="bg-white dark:bg-gray-900">
        {/* pt-16 */}
        <Sidebar
          collapsed={collapsed}
          collapseBehavior="hide"
          aria-label="Default sidebar example"
          className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-gray-50 border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        >
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <Sidebar.Item onClick={() => navigate("/")} className="cursor-pointer" icon={HiChartPie}>
                <p>Dashboard</p>
              </Sidebar.Item>
              <Sidebar.Item onClick={() => navigate("/survey")} className="cursor-pointer" icon={HiInbox} label="2">
                <p>Survey (Admin)</p>
              </Sidebar.Item>
              <Sidebar.Item onClick={() => navigate("/tester")} className="cursor-pointer" icon={HiUser}>
                <p>Tester (Admin)</p>
              </Sidebar.Item>
              <Sidebar.Item onClick={() => navigate("/user")} className="cursor-pointer" icon={HiUser}>
                <p>User (Admin)</p>
              </Sidebar.Item>
              <Sidebar.Collapse icon={HiShoppingBag} label="Nested Menu">
                <Sidebar.Item href="#">Products</Sidebar.Item>
                <Sidebar.Item href="#">Sales</Sidebar.Item>
                <Sidebar.Item href="#">Refunds</Sidebar.Item>
                <Sidebar.Item href="#">Shipping</Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </Sidebar>

        <div className="p-4 sm:ml-64">
          <div className="p-4 mt-14">
            <main>{children}</main>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
