import { Button, Card, CustomFlowbiteTheme, Modal } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";

interface ITesterDetailProps {
  data: {
    id: number;
    name: string;
    phone?: string;
    email?: string;
    status: string;
  };
}

const cardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center p-6",
  },
};

const flatCardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    base: "flex rounded-lg border border-gray-200 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-800",
    // children: "flex h-full flex-col justify-center p-6",
  },
};

function TesterDetail(props: ITesterDetailProps) {
  const { data } = props;
  const [openModal, setOpenModal] = useState<string | undefined>();
  const modalProps = { openModal, setOpenModal };
  return (
    <>
      <Card theme={cardTheme}>
        {Object.entries({
          Fullname: data.name,
          Phone: data.phone,
          Email: data.email,
          Status: data.status,
        }).map(([key, value]) => (
          <>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400">{key}</p>
            <p className="font-medium text-gray-900 truncate dark:text-white mb-4">{value}</p>
          </>
        ))}
      </Card>
      <h2 className="text-xl flex items-center mt-6 mb-4">Survey</h2>
      <Card theme={cardTheme}>
        <p className="font-medium text-gray-900 truncate dark:text-white mb-4 flex items-center">
          <span className="flex w-3 h-3 bg-green-500 rounded-full mr-1.5 flex-shrink-0"></span>
          Survey Assigned
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <Card theme={flatCardTheme} className="max-w-sm">
            {/* <h2 className="font-bold text-lg mb-2">Nining</h2> */}
            <p className="text-gray-900 font-bold mb-2">Menuju Kebahagiaan</p>
          </Card>
          <Card theme={flatCardTheme} className="max-w-sm">
            {/* <h2 className="font-bold text-lg mb-2">Nining</h2> */}
            <p className="text-gray-900 font-bold mb-2">Mencari Permasalahn utama dalam rumah tangga</p>
          </Card>
          <Card theme={flatCardTheme} style={{ width: "100px" }} className="flex items-center hover:bg-gray-100 cursor-pointer" onClick={() => modalProps.setOpenModal("form-elements")}>
            <HiPlus className="text-2xl"></HiPlus>
          </Card>
        </div>
        <p className="font-medium text-gray-900 truncate dark:text-white mb-4 mt-6 flex items-center">
          <span className="flex w-3 h-3 bg-red-500 rounded-full mr-1.5 flex-shrink-0"></span>
          Last Call
        </p>
        <Card theme={flatCardTheme} className="max-w-sm">
          {/* <h2 className="font-bold text-lg mb-2">Nining</h2> */}
          <p className="text-gray-900 mb-2">
            <span className="font-semibold">Nining Meida -</span> at Monday, 10 July, 13:45
          </p>
        </Card>
      </Card>
      <Modal show={modalProps.openModal === "form-elements"} size="xl" popup onClose={() => modalProps.setOpenModal(undefined)}>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-900 dark:text-white">Select Available Survey</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <Card theme={flatCardTheme} className="max-w-sm hover:bg-gray-100 cursor-pointer">
                <p className="text-gray-900 font-bold mb-2">Menuju Kebahagiaan</p>
              </Card>
              <Card theme={flatCardTheme} className="max-w-sm hover:bg-gray-100 cursor-pointer">
                <p className="text-gray-900 font-bold mb-2">Mencari Permasalahn utama dalam rumah tangga</p>
              </Card>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end">
          <Button size="sm" pill color="gray" onClick={() => modalProps.setOpenModal(undefined)}>
            Cancel
          </Button>
          <Button size="sm" pill onClick={() => modalProps.setOpenModal(undefined)}>Select</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TesterDetail;
