import { useState } from "react";
import MyTable from "../MyTable/MyTable";
import UserDetail from "./UserDetail";
import { HiArrowLeft } from "react-icons/hi";

function User() {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  return (
    <>
      {selectedUser == null && (
        <MyTable
          apiUrl="https://api.mockfly.dev/mocks/45bf7300-9413-41d6-8ec5-6cd4533e8b73/users"
          dataPerPage={10}
          mode="server-side"
          columns={[
            { field: "name", title: "Name" },
            { field: "email", title: "Email" },
            { field: "phone", title: "Phone" },
            { field: "status", title: "Status", render: (data: any) => <h2 className="text-green-500">{data.status}</h2> },
          ]}
          actions={[
            { title: "View", action: (data: any) => setSelectedUser(data) },
            { title: "Show Alert", action: (data: any) => alert(JSON.stringify(data)) },
          ]}
        ></MyTable>
      )}
      {selectedUser != null && (
        <>
          <div className="mb-8">
            <h2 className="text-xl flex items-center">
              <HiArrowLeft onClick={() => setSelectedUser(null)} className="mr-4 cursor-pointer hover:text-blue-900"></HiArrowLeft>
              User Detail
            </h2>
          </div>
          <UserDetail data={selectedUser}></UserDetail>
        </>
      )}
    </>
  );
}

export default User;
