import { Button, Card, CustomFlowbiteTheme, Label, Modal, TextInput } from "flowbite-react";
import { useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";

interface ISurveyFormProps {
  //   data: {
  //     id: number;
  //     title: string;
  //     target: number;
  //   };
}

const cardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center p-6",
  },
};

function SurveyForm(props: ISurveyFormProps) {
  //   const { data } = props;
  const [target, setTarget] = useState(0);
  const [title, setTitle] = useState("");
  function handleChange(evt: any) {
    setTarget(evt.currentTarget.value);
  }

  function handleChangeTitle(evt: any) {
    setTitle(evt.currentTarget.value);
  }

  const [openModal, setOpenModal] = useState<string | undefined>();
  const modalProps = { openModal, setOpenModal };
  return (
    <>
      <div className="flex justify-center">
        <Card theme={cardTheme} className="max-w-sm grow">
          <form className="flex max-w-sm flex-col gap-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="title1" value="Title" />
              </div>
              <TextInput id="title1" value={title} onChange={(evt) => handleChangeTitle(evt)} required type="text" />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="target1" value="Target" />
              </div>
              <TextInput id="target1" value={target} onChange={(evt) => handleChange(evt)} required type="number" />
            </div>
            {/* <div>
              <div className="mb-2 block">
                <Label htmlFor="password1" value="Your password" />
              </div>
              <TextInput id="password1" required type="password" />
            </div> */}

            <Button type="submit" onClick={() => modalProps.setOpenModal("pop-up")}>
              Save
            </Button>
          </form>
        </Card>
      </div>
      <Modal show={modalProps.openModal === "pop-up"} size="md" popup onClose={() => modalProps.setOpenModal(undefined)}>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineCheckCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Success saving new survey</h3>
            <div className="flex justify-center gap-4">
              <Button color="success" onClick={() => modalProps.setOpenModal(undefined)}>
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SurveyForm;
