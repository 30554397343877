import axios from "axios";
import { Button, Pagination, Spinner, Table } from "flowbite-react";
import { useEffect, useState } from "react";

interface IMyTableProps {
  apiUrl: string;
  dataPerPage?: number;
  columns: { title: string; field: string; render?: (data: any) => any }[];
  data?: any[];
  actions: { title: string; action: (data: any) => void }[];
  mode: "server-side" | "local";
}

// Yang bsa dilakukan oleh RONI SETIAWAN di response table adalah
// request /collection/paginate?page=1&results=10&sortField=null&sortOrder=null
// response.data {rows: [], count: 100}, rows adalah data di segmen tersebut, dan count adalah total data di collection

function MyTable(props: IMyTableProps) {
  const { apiUrl, dataPerPage = 5, columns, data, actions, mode } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [dataList, setDataList] = useState<any[]>([]);
  const [dataLength, setDataLength] = useState(0);
  const [shownData, setShownData] = useState<any[]>([]);
  const [tableStatus, setTableStatus] = useState<"init" | "loading" | "empty" | "ready" | "error">("init");

  const onPageChange = (page: number) => {
    if (mode == "server-side") setCurrentPage(page);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (mode == "local") {
      setShownData(dataList.slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage));
    } else if (mode == "server-side") {
      setShownData(dataList);
    }
  }, [dataList]);

  const fetchData = async () => {
    setTableStatus("loading");
    try {
      // const response = await axios.get(apiUrl + `?page=${currentPage}`);
      const response = await axios.get(apiUrl + `-page=${currentPage}`);
      setDataList(response.data.data.rows);
      setDataLength(response.data.data.count);
    } catch (error) {
      console.error(error);
    }
    setTableStatus("ready");
  };

  return (
    <>
      <div className="relative">
        {tableStatus == "loading" && (
          <div className="absolute bg-white bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
            <Spinner aria-label="Table loading" size="xl" />
          </div>
        )}
        <div className="container px-4 mx-auto">
          <Table>
            <Table.Row>
              {shownData.length == 0 && (
                <>
                  <div className="absolute bg-white bg-opacity-60 z-10 w-full h-full flex items-center justify-center">Tidak ada data</div>
                  <div style={{ height: "200px" }}></div>
                </>
              )}
            </Table.Row>
            <Table.Head>
              {columns.map((column) => (
                <Table.HeadCell key={column.field}>{column.title}</Table.HeadCell>
              ))}
              <Table.HeadCell>
                <span className="sr-only">Action</span>
              </Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {shownData.map((row) => (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={row.id}>
                  {columns.map((column) => (
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{column.render != undefined ? column.render(row) : row[column.field]}</Table.Cell>
                  ))}
                  <Table.Cell>
                    <div className="flex space-x-2">
                      {actions.map((action) => (
                        <Button pill color="light" onClick={() => action.action(row)} key={action.title} size="xs" className="flex-none">
                          <p>{action.title}</p>
                        </Button>
                      ))}
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
              {/* <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Apple MacBook Pro 17"</Table.Cell>
                    <Table.Cell>Sliver</Table.Cell>
                    <Table.Cell>Laptop</Table.Cell>
                    <Table.Cell>$2999</Table.Cell>
                    <Table.Cell>
                      <a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href="/tables">
                        <p>Edit</p>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      <p>Microsoft Surface Pro</p>
                    </Table.Cell>
                    <Table.Cell>White</Table.Cell>
                    <Table.Cell>Laptop PC</Table.Cell>
                    <Table.Cell>$1999</Table.Cell>
                    <Table.Cell>
                      <a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href="/tables">
                        <p>Edit</p>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">Magic Mouse 2</Table.Cell>
                    <Table.Cell>Black</Table.Cell>
                    <Table.Cell>Accessories</Table.Cell>
                    <Table.Cell>$99</Table.Cell>
                    <Table.Cell>
                      <a className="font-medium text-cyan-600 hover:underline dark:text-cyan-500" href="/tables">
                        <p>Edit</p>
                      </a>
                    </Table.Cell>
                  </Table.Row> */}
            </Table.Body>
          </Table>
        </div>
      </div>
      {shownData.length > 0 && (
        <div className="flex justify-between">
          <div className="flex-none">
            <h4>Debug: Total {dataLength}</h4>
            <h4>
              {(currentPage - 1) * dataPerPage + 1} - {currentPage * dataPerPage > dataLength ? dataLength : currentPage * dataPerPage}
            </h4>
          </div>
          <div className="flex-none">
            <Pagination currentPage={currentPage} onPageChange={onPageChange} showIcons totalPages={Math.ceil(dataLength / dataPerPage)} />
          </div>
        </div>
      )}
    </>
  );
}

export default MyTable;
