import { Button, Card } from "flowbite-react";
import { HiArrowLeft, HiPencil, HiPlus } from "react-icons/hi";
import { useState } from "react";
import SurveyDetail from "./SurveyDetail";
import SurveyForm from "./SurveyForm";

function Survey() {
  const [selectedTest, setSelectedTest] = useState<any>(null);
  const [isInput, setIsInput] = useState<boolean>(false);
  const tests = [
    { id: 1, title: "Menuju Kebahagiaan", target: 1200 },
    { id: 2, title: "Mencari Permasalahn utama dalam rumah tangga", target: 400 },
    // Add more respondents as needed
  ];

  function handleSelect(test: any) {
    setSelectedTest(test);
    setTarget(test.target);
  }

  const [target, setTarget] = useState(0);
  function handleChange(evt: any) {
    setTarget(evt.currentTarget.value);
  }

  return (
    <>
      {!selectedTest && !isInput && (
        <div className="container mx-auto">
          <div className="flex items-start justify-between">
            <h1 className="text-2xl font-bold mb-8">Available Survey</h1>
            <Button size="sm" onClick={() => setIsInput(true)}>
              <HiPlus className="mr-2"></HiPlus>
              New Survey
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {tests.map((t) => (
              <Card className="max-w-sm" key={t.id}>
                <h2 className="font-bold text-lg mb-2">{t.title}</h2>
                <p className="text-gray-500 mb-2">
                  <span className="font-semibold">Target:</span> {t.target}
                </p>
                <p className="text-gray-500 mb-2">
                  <span className="font-semibold">Fulfilled:</span> {t.target * 0.53}
                  <span className="font-semibold text-green-500"> (53%)</span>
                </p>
                <Button size="sm" color="light" pill className="w-full" onClick={() => handleSelect(t)}>
                  <p>View</p>
                </Button>
              </Card>
            ))}
          </div>
        </div>
      )}
      {selectedTest && !isInput && (
        <>
          <div className="flex items-start justify-between mb-8">
            <h2 className="text-xl flex items-center">
              <HiArrowLeft onClick={() => setSelectedTest(null)} className="mr-4 cursor-pointer hover:text-blue-900"></HiArrowLeft>
              {selectedTest.title}
            </h2>
            <Button size="sm">
              <HiPencil className="mr-2"></HiPencil>
              Edit
            </Button>
          </div>
          <SurveyDetail data={selectedTest}></SurveyDetail>
        </>
      )}
      {isInput && (
        <>
          <div className="mb-8">
            <h2 className="text-xl flex items-center">
              <HiArrowLeft onClick={() => setIsInput(false)} className="mr-4 cursor-pointer hover:text-blue-900"></HiArrowLeft>
              New Survey
            </h2>
          </div>
          <SurveyForm></SurveyForm>
        </>
      )}
    </>
  );
}

export default Survey;
