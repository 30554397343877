import MyTable from "../MyTable/MyTable";
import { useState } from "react";
import { HiArrowLeft } from "react-icons/hi";
import TesterDetail from "./TesterDetail";

function Tester() {
  const [selectedTester, setSelectedTester] = useState<any>(null);
  return (
    <>
      {selectedTester == null && (
        <MyTable
          apiUrl="https://api.mockfly.dev/mocks/45bf7300-9413-41d6-8ec5-6cd4533e8b73/users"
          dataPerPage={10}
          mode="server-side"
          columns={[
            { field: "name", title: "Name" },
            { field: "email", title: "Email" },
            { field: "phone", title: "Phone" },
            { field: "status", title: "Status" },
          ]}
          actions={[
            { title: "View", action: (data: any) => setSelectedTester(data) },
            { title: "Show Alert", action: (data: any) => alert(JSON.stringify(data)) },
          ]}
        ></MyTable>
      )}

      {selectedTester != null && (
        <>
          <div className="mb-8">
            <h2 className="text-xl flex items-center">
              <HiArrowLeft onClick={() => setSelectedTester(null)} className="mr-4 cursor-pointer hover:text-blue-900"></HiArrowLeft>
              Tester Detail
            </h2>
          </div>
          <TesterDetail data={selectedTester}></TesterDetail>
        </>
      )}
    </>
  );
}

export default Tester;
