import { Button, Card, CustomFlowbiteTheme } from "flowbite-react";
import { useNavigate } from "react-router-dom";

const WantToCall = () => {
  const cardTheme: CustomFlowbiteTheme["card"] = {
    root: {
      children: "flex h-full flex-col justify-start p-6",
    },
  };

  const respondents = [
    { id: 1, name: "John Doe", contact: "john.doe@example.com", screeningStatus: "Available", age: 28, location: "Bandung" },
    { id: 2, name: "Jane Smith", contact: "jane.smith@example.com", screeningStatus: "Available", age: 35, location: "Cimahi" },
    { id: 3, name: "Joe Mama", contact: "joe.mama@example.com", screeningStatus: "Available", age: 180, location: "Jakarta" },
  ];

  const rescheduled = [{ id: 1, name: "James Ilham", contact: "ajames@example.com", screeningStatus: "Available", age: 28, location: "Bandung", timestamp: "14 Jun 2023, 14:25" }];

  const navigate = useNavigate();
  return (
    <>
      {/* py-8 */}
      <div className="container mx-auto">
        <h1 className="text-2xl font-bold mb-8">Available Respondents</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {respondents.map((respondent) => (
            <Card theme={cardTheme} className="max-w-sm" key={respondent.id}>
              <h2 className="font-bold text-lg mb-2">{respondent.name}</h2>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Contact:</span> {respondent.contact}
              </p>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Age:</span> {respondent.age}
              </p>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Location:</span> {respondent.location}
              </p>
              <p className="text-gray-500">
                <span className="font-semibold">Screening Status:</span> {respondent.screeningStatus}
              </p>
              <Button size="sm" color="success" pill className="w-full mt-8" onClick={() => navigate("/on-call")} disabled={respondent.id !== 1}>
                <p>Call</p>
              </Button>
            </Card>
          ))}
        </div>
      </div>

      <div className="container mx-auto mt-12">
        <h1 className="text-2xl font-bold mb-8">Re-scheduled Survey</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {rescheduled.map((respondent) => (
            <Card theme={cardTheme} className="max-w-sm" key={respondent.id}>
              <h2 className="font-bold text-lg mb-2">{respondent.name}</h2>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Time to call: </span>
                <span className="font-semibold text-red-600">{respondent.timestamp}</span>
              </p>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Contact:</span> {respondent.contact}
              </p>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Age:</span> {respondent.age}
              </p>
              <p className="text-gray-500 mb-2">
                <span className="font-semibold">Location:</span> {respondent.location}
              </p>
              <p className="text-gray-500">
                <span className="font-semibold">Screening Status:</span> {respondent.screeningStatus}
              </p>
              {/* <Tooltip content="The call isn't available now"> */}
                <Button size="sm" color="success" pill className="w-full mt-8" onClick={() => navigate("/on-call")} disabled={true}>
                  <p>Call</p>
                </Button>
              {/* </Tooltip> */}
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};

export default WantToCall;
