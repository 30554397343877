import { Card, CustomFlowbiteTheme, Label, TextInput } from "flowbite-react";

interface IUserDetailProps {
  data: {
    id: number;
    name: string;
    phone?: string;
    email?: string;
    status: string;
  };
}

const cardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center p-6",
  },
};

function UserDetail(props: IUserDetailProps) {
  const { data } = props;
  return (
    <>
      <Card theme={cardTheme}>
        {Object.entries({
          Fullname: data.name,
          Phone: data.phone,
          Email: data.email,
          Status: data.status,
        }).map(([key, value]) => (
          <>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400">{key}</p>
            <p className="font-medium text-gray-900 truncate dark:text-white mb-4">{value}</p>
          </>
        ))}
      </Card>
      {/* <div className="flex max-w-md flex-col gap-4">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="base" value="Fullname" />
          </div>
          {data.name}
          <TextInput id="base" sizing="md" type="text" />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="base" value="Phone" />
          </div>
          {data.phone}
          <TextInput id="base" sizing="md" type="text" />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="base" value="Email Address" />
          </div>
          {data.email}
          <TextInput id="base" sizing="md" type="text" />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="base" value="Status" />
          </div>
          {data.status}
          <TextInput id="base" sizing="md" type="text" />
        </div>
      </div> */}
    </>
  );
}

export default UserDetail;
