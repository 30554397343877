import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import NotFound from "./pages/NotFound";
import OnCallPage from "./pages/OnCall";
import User from "./pages/User/User";
import Layout from "./pages/Layout";
import { Flowbite } from "flowbite-react";
import Survey from "./pages/Survey/Survey";
import Tester from "./pages/Tester/Tester";

function App() {
  return (
    <Flowbite>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/main" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/on-call" element={<OnCallPage />} />
            <Route path="/user" element={<User />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/tester" element={<Tester />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Router>
    </Flowbite>
  );
}

export default App;
