import { Card, CustomFlowbiteTheme, Label, RangeSlider } from "flowbite-react";

interface ISurveyDetailProps {
  data: {
    id: number;
    title: string;
    target: number;
  };
}

const cardTheme: CustomFlowbiteTheme["card"] = {
  root: {
    children: "flex h-full flex-col justify-center p-6",
  },
};

function SurveyDetail(props: ISurveyDetailProps) {
  const { data } = props;
  return (
    <>
      <Card theme={cardTheme}>
        {Object.entries({
          Title: data.title,
          Target: data.target,
        }).map(([key, value]) => (
          <>
            <p className="text-sm text-gray-500 truncate dark:text-gray-400">{key}</p>
            <p className="font-medium text-gray-900 truncate dark:text-white mb-4">{value}</p>
          </>
        ))}
        <span className="text-red-600 font-bold">TODO: add set target, edit props</span>
        <div>
          <div className="mb-1 block">
            <Label htmlFor="default-range" value="Default" />
          </div>
          <RangeSlider id="default-range" />
        </div>
      </Card>
      {/* <form className="flex max-w-sm flex-col gap-4 mt-8">
        <div>
          <div className="mb-2 block">
            <Label htmlFor="target1" value="Target" />
          </div>
          <TextInput id="target1" value={target} onChange={(evt) => handleChange(evt)} required type="number" />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Your password" />
          </div>
          <TextInput id="password1" required type="password" />
        </div>

        <Button type="submit">Save</Button>
      </form> */}
    </>
  );
}

export default SurveyDetail;
